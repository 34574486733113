<script lang="ts">
  import MenuMobile from "src/app/MenuMobile.svelte"
  import MenuDesktop from "src/app/MenuDesktop.svelte"
  import {toastProgress} from "src/app/state"
  import {
    env,
    session,
    writable,
    getEventHints,
    getClientTags,
    tagsFromContent,
    publishToZeroOrMoreGroups,
    getGroupPublishHints,
  } from "src/engine"
  import {currencyOptions} from "src/util/i18n"
  import {createEvent, now, Tags} from "paravel"
  import {join, whereEq, identity} from "ramda"

  export let quote = null
  export let group = null
  export let initialValues = {}

  const defaultGroups = quote ? Tags.from(quote).circles().all() : [group].filter(identity)

  let opts = {
    title: "",
    warning: "",
    summary: "",
    price: "",
    currency: currencyOptions.find(whereEq({code: "SAT"})),
    relays: getGroupPublishHints(defaultGroups),
    groups: defaultGroups,
    anonymous: false,
    location: null,
    start: null,
    end: null,
    date: null,
    ...initialValues,
  }

  let ms = 60000 // 60000 = One minute. add another 0 for 10 minutes.
  const publish = () => {periodicallyPublishEvents()}

  let clear
  $: {
  clearInterval(clear)
  clear = setInterval(publish, ms)
  }

  function deleteStoredEvent(index) {
    var newPostsArray = []
    var storedPosts = JSON.parse(localStorage.getItem("scheduledNostrEvents"));
    storedPosts.splice(index,1)
    localStorage.setItem("scheduledNostrEvents", JSON.stringify(storedPosts));
    // reload page after one minute
    // setInterval(window.location.reload(), ms)
  }

  async function periodicallyPublishEvents() {

    JSON.parse(localStorage.getItem('scheduledNostrEvents')).forEach(async(event, index) => {
      
        const whenPublish = new Date(event.whenPublish);
        const current = new Date();
        if (whenPublish.getTime() < current.getTime()) {
            console.log('trying to publish')
            const {pubs} = await publishToZeroOrMoreGroups('lengthMoreThanOne', event, opts)
            pubs[0].on("progress", toastProgress)
            deleteStoredEvent(index)
        } else if (whenPublish.getTime() > current.getTime()) {
            console.log('not time yet')
        }
    });
  } 

  let innerWidth = 0
</script>

<svelte:window bind:innerWidth />

{#if innerWidth < 1024}
  <MenuMobile />
{:else}
  <MenuDesktop />
{/if}
