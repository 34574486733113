<script lang="ts">
  import cx from "classnames"
  import Anchor from "src/partials/Anchor.svelte"
  import PersonCircle from "src/app/shared/PersonCircle.svelte"
  import PersonName from "src/app/shared/PersonName.svelte"
  import PersonHandle from "src/app/shared/PersonHandle.svelte"
  import {router} from "src/app/router"
  import {
    session,
    deriveFollowing,
    follow,
    unfollow,
  } from "src/engine"

  export let pubkey
  const isSelf = $session?.pubkey === pubkey
  const following = deriveFollowing(pubkey)
  const unfollowPerson = () => unfollow(pubkey)
  const followPerson = () => follow("p", pubkey)
  export let inert = false
</script>

{#if inert}
  <div style="position:relative;">
      <div class="flex flex-col" style="position: absolute;z-index: 1000;right: 0px; top: -10px">
      {#if !isSelf}
        {#if $following}
              <i class="fa fa-user-minus cursor-pointer" on:click={unfollowPerson} />
            {:else}
              <i class="fa fa-user-plus cursor-pointer" on:click={followPerson} />
            {/if}
      {/if}
    </div>
    <div class={cx($$props.class, "relative flex gap-4")}>
      <PersonCircle class="h-12 w-12" {pubkey} />
      <div class="flex flex-col" style="min-width: 48px;">
        <PersonName {pubkey} />
        <PersonHandle {pubkey} />
      </div>
    </div>
  </div>
{:else}
  <div style="position:relative;">
    <div class="flex flex-col" style="position: absolute;z-index: 1000;right: 0px;  top: -10px">
      {#if !isSelf}
        {#if $following}
              <i class="fa fa-user-minus cursor-pointer" on:click={unfollowPerson} />
            {:else}
              <i class="fa fa-user-plus cursor-pointer" on:click={followPerson} />
            {/if}
      {/if}
    </div>
    <Anchor
      modal
      href={router.at("people").of(pubkey).toString()}
      class={cx($$props.class, "relative flex gap-4")}>
      <PersonCircle class="h-12 w-12" {pubkey} />
      <div class="flex flex-col" style="min-width: 48px;">
        <PersonName {pubkey} />
        <PersonHandle {pubkey} />
      </div>
    </Anchor>
  </div>
{/if}
